import axios from 'axios'
import {CookiesService} from './Cookie.service'
import {AuthApi} from './AuthApi.service'
import MyAxiosInstance from './axiosInstance'

const MyAxiosInstanceDocument = axios.create({
    baseURL: process.env.REACT_APP_DOCUMENT_URL,
})

const cookies = new CookiesService()
let isRefreshing = false
const failedQueue: {
    resolve: (value?: unknown) => void
    reject: (reason?: any) => void
}[] = []

const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error)
        } else {
            prom.resolve(token)
        }
    })
    failedQueue.length = 0
}

MyAxiosInstanceDocument.interceptors.request.use(
    async (config) => {
        let auth = cookies.getAuthData()
        config.headers.Authorization = `Bearer ${auth?.access_token}`
        return config
    },
    (error) => Promise.reject(error)
)

MyAxiosInstanceDocument.interceptors.response.use(
    (response) => {
        return response
    },
    async (error) => {
        const prevRequest = error?.config
        if (
            (error?.response?.status === 403 ||
                error?.response?.status === 401) &&
            !prevRequest?.sent
        ) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({resolve, reject})
                })
            } else {
                prevRequest.sent = true
                try {
                    const auth_api = new AuthApi()
                    isRefreshing = true
                    const {access_token} = await auth_api.doRefreshToken()
                    isRefreshing = false
                    processQueue(null, access_token)
                } catch (error) {
                    processQueue(error, null)
                    console.log('🚀 ~ error:', error)
                    Promise.reject(error)
                }
                return MyAxiosInstanceDocument(prevRequest)
            }
        }
        return Promise.reject(error)
    }
)

export default MyAxiosInstanceDocument
