import styled from 'styled-components'
import LeftBar from '../../home/components/leftBar'
import HistoryProvider from '../../../contexts/history'
import Projetos from './body'

const DIV = styled.div`
    display: flex;
    height: 100vh;
    overflow: hidden !important;.
`

export default function ProjetosPage() {
    return (
        <DIV>
            <HistoryProvider>
                <LeftBar />
                <Projetos />
            </HistoryProvider>
        </DIV>
    )
}
