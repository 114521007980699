import { useContext } from 'react'
import { AuthContext } from '../../../../../contexts/auth'
import styled from 'styled-components'
import { COLORS } from '../../../../../utils/colors'
import { HistoryContext } from '../../../../../contexts/history'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const List = styled.ul`
    list-style: none;
    border-radius: 8px;
    padding: 3px;
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    background-color: white;
    max-width: 80%;
    width: 300px;
    z-index: 999;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
`

const Item = styled.li<{ clickable: boolean }>`
    border-bottom: none;
    padding: 10px;
    color: ${COLORS.cinzaEscuro};
    font-size: 13px;
    word-break: break-all;
    text-align: left;

    &:hover {
        background-color: ${({ clickable }) =>
        clickable ? COLORS.branco : 'transparent'};
        cursor: ${({ clickable }) => clickable ? 'pointer' : 'auto'};
    }
`

const Divider = styled.div`
    height: 2px;
    background-color: ${COLORS.cinzaClaro};
    margin: 8px 0;
`

const InfoItem = styled(Item)`
    color: #a0a0a0;
`

const ItemWithIcon = styled(Item)`
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${COLORS.cinzaEscuro};

    svg {
        color: #a0a0a0;
    }
`

export default function NavList() {
    const { userInfo, logout } = useContext(AuthContext)
    const {
        limits,
        setUnarchieveModal,
        subscription,
    } = useContext(HistoryContext)

    const dateStr = subscription?.exp_at
    const handleOpen = () => setUnarchieveModal(true)

    let formattedDate = 'Data não disponível'

    if (dateStr) {
        const date = new Date(dateStr)
        formattedDate = date.toLocaleDateString('pt-BR', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        })
    }

    return (
        <List>
            <Item clickable={false}>
                {userInfo.email}
            </Item>
            <InfoItem clickable={false}>
                Renovação em: {formattedDate}
            </InfoItem>
            <InfoItem clickable={false}>
                Uso: {limits.current_answers}/{limits.max_answers}
            </InfoItem>

            <Divider />

            <ItemWithIcon clickable={true} onClick={() => (window.location.href = '/planos')}>
                <CreditCardIcon fontSize="small" />Assinar
            </ItemWithIcon>
            <ItemWithIcon clickable={true} onClick={handleOpen}>
                <UnarchiveIcon fontSize="small" />Desarquivar Chats
            </ItemWithIcon>
            <ItemWithIcon clickable={true} onClick={() => (window.location.pathname = '/configuracoes')}>
                <ManageAccountsIcon fontSize="small" />Configurações
            </ItemWithIcon>
        </List>
    )
}
