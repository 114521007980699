import {useState} from 'react'
import styled, {keyframes} from 'styled-components'
import userIcon from '../assets/guru.png'

const spin = keyframes`
      0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
    position: fixed;
    top: 40%;
    z-index: 99999;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 50px;
    height: 50px;

    &::after {
        content: ' ';
        display: block;
        width: 34px;
        height: 34px;
        margin: 8px;
        border-radius: 50%;
        border: 6.4px solid currentColor;
        border-color: currentColor transparent currentColor transparent;
        animation: ${spin} 1.2s linear infinite;
    }
`
const dotsAnimation = keyframes`
    0% {
        content: '';
    }
    33% {
        content: '.';
    }
    66% {
        content: '..';
    }
    100% {
        content: '...';
    }
`

const LoadingContainer = styled.div`
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    flexs & img {
        width: 44px;
        height: 44px;
        margin-right: 10px;
    }

    & span {
        font-size: 17px;
        font-weight: bold;
        color: green; /* Cor verde */
        display: inline-block;

        &::after {
            content: '...';
            animation: ${dotsAnimation} 1.5s infinite;
        }
    }
`

export const useLoading = () => {
    function LoadingMessage() {
        return (
            <LoadingContainer>
                <img src={userIcon} alt="Ícone do guru" />
                <span style={{marginLeft: '10px'}}></span>
            </LoadingContainer>
        )
    }

    const [isLoading, setIsLoading] = useState(false)
    return {
        Spinner: isLoading ? LoadingSpinner : Empty,
        isLoading,
        setIsLoading,
    }
}

const LoadingSpinner = () => {
    return <Spinner></Spinner>
}

const LoadingMessage = () => {
    return <LoadingMessage />
}

const Empty = () => {
    return <></>
}
