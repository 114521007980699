import {SetStateAction, useContext, useEffect, useState, useRef} from 'react'
import styled from 'styled-components'
import {COLORS} from '../../../../../utils/colors'
import {HistoryContext} from '../../../../../contexts/history'
import ListItem from './ListItem'
import {InputAdornment, TextField} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import {AssistantApi} from '../../../../../services/AssistantApi.service'
import {threadType} from '../../../../../types/thread'

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`

const Title = styled.h2`
    font-size: 20px;
    font-weight: 400;
    margin: 15px 0;
    text-align: center;
    color: ${COLORS.cinzaEscuro};
`

const List = styled.ul`
    list-style: none;
    max-height: 300px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Loader = styled.div`
    text-align: center;
    padding: 10px;
    color: ${COLORS.cinzaEscuro};
`

export default function History() {
    const {historyArray: contextHistoryArray} = useContext(HistoryContext)
    const [searchTerm, setSearchTerm] = useState('')
    const [historyPage, setHistoryPage] = useState(1)
    const [historyArray, setHistoryArray] = useState<threadType[]>([])
    const limit = 15
    const loaderRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setHistoryArray(contextHistoryArray)
    }, [contextHistoryArray])

    const handleSearchChange = (event: {
        target: {value: SetStateAction<string>}
    }) => {
        setSearchTerm(event.target.value)
    }

    const loadMoreItems = async () => {
        const api = new AssistantApi()
        setHistoryPage((prevPage) => prevPage + 1)

        const newThreads = await api.getThreads(limit, historyPage * limit)

        if (newThreads.length > 0) {
            setHistoryArray((prevArray) => [...prevArray, ...newThreads])
        }
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    loadMoreItems()
                }
            },
            {threshold: 1.0}
        )

        if (loaderRef.current) {
            observer.observe(loaderRef.current)
        }

        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current)
            }
        }
    }, [loaderRef.current])

    const filteredHistory = historyArray
        .filter((item) => {
            return item.name.toLowerCase().includes(searchTerm.toLowerCase())
        })
        .slice(0, historyPage * limit)

    return (
        <Container>
            <TextField
                size="small"
                variant="outlined"
                placeholder="Procure pelo chat..."
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                sx={{mb: 2, mx: 2}}
            />
            <List>
                {filteredHistory?.map((item) => (
                    <ListItem item={item} key={item.guid} />
                ))}
                <Loader ref={loaderRef}>Carregando mais...</Loader>
            </List>
        </Container>
    )
}
