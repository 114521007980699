import {Box, Button, Modal, Typography} from '@mui/material'
import {useContext, useState} from 'react'
import styled from 'styled-components'
import archiveIcon from '../../../../../assets/archive.svg'
import {HistoryContext} from '../../../../../contexts/history'
import {threadType} from '../../../../../types/thread'
import {COLORS} from '../../../../../utils/colors'
import {Icon} from './Icon'

interface ListItemProps {
    item: threadType
}

const ListItemStyled = styled.li<{isSelected: boolean}>`
    font-weight: 300;
    background-color: ${({isSelected}) =>
        isSelected ? 'transparent' : 'transparent'};
    cursor: pointer;
    width: 250px;
    align-self: center;
    margin-left: 10px;
    border-radius: 5px;
    font-size: 15px;
    text-align: center;
    position: relative;
    display: flex;
    overflow: visible;
    & span {
        text-align: start;
        padding: 10px 15px;
        display: inline-block;
        height: 100%;
        flex: 1;
    }
`
const Icons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-right: 10px;
`
export default function ListItem({item}: ListItemProps) {
    const {selected, setSelected, archiveThread, deleteThread} =
        useContext(HistoryContext)
    const isSelected = selected?.guid === item.guid

    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    function toggleItem() {
        if (isSelected) {
            return
        }
        setSelected(item)
    }

    return (
        <>
            <ListItemStyled isSelected={isSelected}>
                <span onClick={toggleItem}>
                    {item.name === 'Undefined'
                        ? 'Novo Chat'
                        : item.name.slice(0, 23)}
                    {item.name?.length < 23 ? '' : '...'}
                </span>

                {isSelected && item.name !== 'Novo Chat' && (
                    <Icons>
                        <Icon
                            tooltip="Arquivar"
                            onClick={handleOpen}
                            position="right"
                            src={archiveIcon}
                            alt="Arquivar Thread"
                        />
                    </Icons>
                )}
            </ListItemStyled>

            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 300,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    <Typography variant="h6" component="h2">
                        Confirmar Arquivamento
                    </Typography>
                    <Typography sx={{mt: 2}}>
                        Tem certeza que deseja arquivar a conversa{' '}
                        <b style={{color: COLORS.azulClaro}}>{item?.name}</b>?
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            mt: 3,
                        }}
                    >
                        <Button onClick={handleClose} sx={{mr: 1}}>
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => archiveThread(item.guid)}
                        >
                            Arquivar
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}
