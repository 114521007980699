import styled from 'styled-components'
import logo from '../../../../assets/licito-logo.svg'
import History from './history/history'
import {useContext, useState, useRef, useEffect} from 'react'
import HideShowBarButton from '../../../../globalComponents/hideShowBarButton'
import Button from '../../../../globalComponents/ButtonPattern'
import {AuthContext} from '../../../../contexts/auth'
import NavList from './navList'
import {HistoryContext} from '../../../../contexts/history'
import {List} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import chatsIcon from '../../../../assets/chats.svg'
import documentsIcon from '../../../../assets/documents.svg'
import exitIcon from '../../../../assets/exit.svg'

const Section = styled.section<{show: boolean}>`
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px 44px 0;
    justify-content: space-between;
    position: relative;
    transform: translateX(${({show}) => (show ? '0' : '-100%')});
    transition: all 0.3s;
    z-index: 55;
    border-right: 1px solid #e0e0e0;
    @media (max-width: 640px) {
        position: absolute;
        min-height: 100vh;
    }
`

const Logo = styled.img`
    width: 200px;
`

const ListWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
`

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
`

interface Props {
    visibility: string
}

const Alert = styled.div<Props>`
    color: ${(props) => props.visibility};
    transition: color 0.25s;
`

export default function LeftBar() {
    const [show, setShow] = useState(true)
    const {userInfo, logout} = useContext(AuthContext)
    const {createNewChat, limits, alertVisibility, isChat, setIsChat} =
        useContext(HistoryContext)
    const [openList, setOpenList] = useState(false)
    const listRef = useRef<HTMLDivElement | null>(null)
    const [selectedButton, setSelectedButton] = useState<
        'chats' | 'documentos' | null
    >(() => {
        return window.location.pathname === '/projetos' ? 'documentos' : 'chats'
    })
    const navigate = useNavigate()
    const handleChatsClick = () => {
        setSelectedButton('chats')
        setIsChat(true)
        createNewChat()
        navigate('/')
    }
    const handleDocumentosClick = () => {
        setSelectedButton('documentos')
        setIsChat(false)
        navigate('/projetos')
    }
    const createInitialsSVG = (firstName: string, lastName?: string) => {
        const initials =
            (firstName?.[0] || '').toUpperCase() +
            (lastName?.[0] || '').toUpperCase()
        const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="30" height="30">
          <circle cx="20" cy="20" r="20" fill="#012441"/>
          <text x="20" y="25" text-anchor="middle" fill="white" font-size="16" font-family="Arial">${initials}</text>
        </svg>`
        return `data:image/svg+xml;base64,${btoa(svg)}`
    }

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                listRef.current &&
                !listRef.current.contains(event.target as Node)
            ) {
                setOpenList(false)
            }
        }
        if (openList) {
            document.addEventListener('mousedown', handleClickOutside)
        } else {
            document.removeEventListener('mousedown', handleClickOutside)
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [openList])

    return (
        <Section show={show}>
            <Wrapper>
                <Logo src={logo} alt="Logo Schief.ai" />
                <Button
                    onclick={handleChatsClick}
                    IconAltText="Criar novo chat"
                    text="Chats"
                    SVGIcon={chatsIcon}
                    align="left"
                    customStyle={{
                        border: 'none',
                        borderRadius: '0',
                        justifyContent: 'flex-start',
                        width: '100%',
                        paddingLeft: '20px',
                    }}
                    customIconStyle={{
                        width: '30px',
                        height: '30px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    selectedButton={selectedButton === 'chats'}
                />
                <Button
                    onclick={handleDocumentosClick}
                    IconAltText="Criar Documento"
                    text="Documentos"
                    SVGIcon={documentsIcon}
                    align="left"
                    customStyle={{
                        border: 'none',
                        borderRadius: '0',
                        justifyContent: 'flex-start',
                        width: '100%',
                        paddingLeft: '20px',
                    }}
                    customIconStyle={{
                        width: '30px',
                        height: '30px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    selectedButton={selectedButton === 'documentos'}
                />
                <Alert visibility={alertVisibility}>
                    Você já está em um novo chat!
                </Alert>
                {isChat && window.location.pathname == '/' ? (
                    <History />
                ) : (
                    <List style={{width: '90%', marginBottom: '300px'}}></List>
                )}
            </Wrapper>

            <ListWrapper ref={listRef}>
                {openList && <NavList />}
                <ButtonsContainer>
                    <Button
                        onclick={() => setOpenList((prev) => !prev)}
                        IconAltText="Abrir Lista de Configurações"
                        text={`${userInfo.first_name}`}
                        SVGIcon={createInitialsSVG(
                            userInfo.first_name,
                            userInfo.last_name
                        )}
                        align="left"
                        endText={`${limits.current_answers}/${limits.max_answers}`}
                        tooltip="Conta"
                        customStyle={{
                            border: 'none',
                            padding: '8px 16px',
                            background: 'transparent',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            width: '90%',
                            justifyContent: 'flex-start',
                            paddingLeft: '20px',
                        }}
                    />
                    <Button
                        onclick={logout}
                        IconAltText="sair"
                        text="Sair"
                        SVGIcon={exitIcon}
                        align="left"
                        customStyle={{
                            border: 'none',
                            padding: '8px 16px',
                            background: 'transparent',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            width: '90%',
                            justifyContent: 'flex-start',
                            paddingLeft: '20px',
                        }}
                    />
                </ButtonsContainer>
            </ListWrapper>
            <HideShowBarButton
                position="right"
                offset="-20px"
                show={show}
                setShow={setShow}
            />
        </Section>
    )
}
