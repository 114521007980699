import React, {createContext, useEffect, useMemo, useState} from 'react'
import {IAuthData, IUserInfo} from '../types/user'
import {AuthApi} from '../services/AuthApi.service'
import {CookiesService} from '../services/Cookie.service'
import {AssistantApi} from '../services/AssistantApi.service'

interface IAuthContext {
    login: (user: string, password: string) => void
    logout: () => void
    authData: IAuthData | null
    signUp: (
        firstName: string,
        lastName: string,
        email: string,
        password: string
    ) => void
    userInfo: IUserInfo
}

// org_admin: false
const template = {
    created_at: new Date(),
    email: '',
    first_name: '',
    guid: '',
    is_active: true,
    last_name: '',
    last_session: new Date(),
    modified_at: new Date(),
    organization: {
        cnpj: '',
        created_at: new Date(),
        guid: '',
        name: '',
    },
}
export const AuthContext = createContext<IAuthContext>({
    login: () => {},
    logout: () => {},
    authData: null,
    signUp: () => {},
    userInfo: template,
})

const AuthProvider = ({children}: {children: React.ReactNode}): JSX.Element => {
    const [authData, setAuthData] = useState<IAuthData | null>(null)
    const [userInfo, setUserInfo] = useState<IUserInfo>(template)
    const cookiesService = useMemo(() => {
        return new CookiesService()
    }, [])

    async function login(user: string, password: string) {
        try {
            const api = new AuthApi()
            await api.login(user, password)
            window.location.pathname = '/'
        } catch (error: any) {
            throw error
        }
    }

    async function getUserData() {
        try {
            const api = new AssistantApi()
            const userInfo = await api.getUser()
            const auth_api = new AuthApi()
            setUserInfo(userInfo)
        } catch (error) {
            throw error
        }
    }

    async function signUp(
        firstName: string,
        lastName: string,
        email: string,
        password: string
    ) {
        try {
            const api = new AuthApi()
            await api.signUp(firstName, lastName, email, password)
            window.location.pathname = '/'
        } catch (error) {
            throw error
        }
    }

    function logout(): void {
        setAuthData(null)
        cookiesService.removeAuthData()
        cookiesService.removeAssistant()
        window.location.pathname = '/login'
    }

    useEffect(() => {
        const authdata = cookiesService.getAuthData()
        if (authdata.access_token) {
            setAuthData(authdata)
            getUserData()
            console.log('ok')
            return
        }
        console.log('not ok')
    }, [])

    return (
        <AuthContext.Provider
            value={{logout, login, signUp, authData, userInfo}}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
