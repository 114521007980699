import React, {useContext, useEffect, useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import {
    Button,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    IconButton,
    Stack,
    InputAdornment,
} from '@mui/material'
import {toast} from 'react-toastify'
import Background from '../../../assets/background.svg'
import {HistoryContext} from '../../../contexts/history'
import {Question} from '../../../types/question'
import {AssistantApi} from '../../../services/AssistantApi.service'
import CloseIcon from '@mui/icons-material/Close'

interface Option {
    content: string
    index: number
}

interface SelectType {
    multiple?: boolean
    options: Option[]
    default?: string
    placeholder?: string
    allowed_answer_type?: string
}

interface RadioType {
    options: Option[]
}

interface QuestionType extends Omit<Question, 'type'> {
    type: {
        text?: boolean
        radio?: RadioType
        select?: SelectType
        table?: boolean
        checkbox?: boolean
    }
    question_type: 'text' | 'radio' | 'select' | 'table' | 'checkbox'
}

interface RowData {
    id: number
    product: string
    qnt: string
    price: string
    total_price: string
}

type FormDataValue =
    | string
    | boolean
    | string[]
    | {[key: string]: string | boolean}

interface FormData {
    [key: string]: FormDataValue
}

const formatCurrency = (value: number): string => {
    return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    })
}

const parseCurrencyInput = (value: string): number => {
    const cleanValue = value.replace(/[R$\s.]/g, '').replace(',', '.')
    return Number(cleanValue) || 0
}

function createEmptyRow(id: number): RowData {
    return {
        id,
        product: '',
        qnt: '',
        price: '',
        total_price: '0',
    }
}

const Footer = styled.div`
    height: 57px;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    position: absolute;
    bottom: 0;
    left: 0;
`

const GradientButton = styled.button`
    background: linear-gradient(90deg, #1db8e8 0%, #26e87d 100%);
    border: none;
    color: white;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        opacity: 0.9;
    }
`

const Page = styled.div`
    width: 100%;
    height: 100vh;
    margin-top: 0;
    padding: 64px 48px 48px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    background-color: #f6f8fa;
    background-image: url(${Background});
    background-repeat: no-repeat;
    background-position: right 370px;
    background-size: 800px;
`

const Header = styled.div`
    width: 100%;
    margin-bottom: 32px;
`

const ContentContainer = styled.div`
    width: 70%;
    max-width: 1000px;
    background-color: #ffffff;
    padding: 32px;
    border-radius: 10px;
    margin-bottom: 24px;
    align-self: center;
`

const NavigationButtons = styled.div`
    width: 70%;
    max-width: 1000px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin: 16px auto 48px;
`

export default function DocumentGenerator() {
    const navigate = useNavigate()
    const {questionArray, setQuestionArray} = useContext(HistoryContext)
    const [projectId, setProjectId] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [finished, setFinished] = useState(false)
    const api = useMemo(() => new AssistantApi(), [])
    const [projectName, setProjectName] = useState('')
    const [newTables, setNewTables] = useState<RowData[][]>([
        [createEmptyRow(1)],
    ])
    const [activeStep, setActiveStep] = useState(0)
    const [formData, setFormData] = useState<FormData>({})
    interface ResumeItem {
        question_title: string
        content: {
            text: string
            table: RowData[][]
        }
    }

    const [resume, setResume] = useState<ResumeItem[]>([])

    const calculateRowTotal = (
        quantidade: string,
        precounitario: string
    ): string => {
        const qtd = parseFloat(quantidade) || 0
        const price = parseCurrencyInput(precounitario)
        return (qtd * price).toString()
    }

    const calculateTableTotal = (table: RowData[]): number => {
        return table.reduce((sum, row) => {
            return sum + parseFloat(row.total_price || '0')
        }, 0)
    }

    const calculateGlobalTotal = (): number => {
        return newTables.reduce((sum, table) => {
            return sum + calculateTableTotal(table)
        }, 0)
    }

    const isCurrentQuestionAnswered = (): boolean => {
        if (!questionArray[activeStep]) return false

        const currentQuestion = questionArray[activeStep] as QuestionType
        const questionId = currentQuestion.id
        const questionType = currentQuestion.question_type
        const currentAnswer = formData[questionId]

        // console.log('perguntas respondidas:', {
        //     questionType,
        //     questionId,
        //     currentAnswer,
        //     formData
        // })

        switch (questionType) {
            case 'text':
                return Boolean(
                    currentAnswer && (currentAnswer as string).trim()
                )

            case 'radio':
                return Boolean(currentAnswer)

            case 'select':
                if (currentQuestion.type.select?.multiple) {
                    return (
                        Array.isArray(currentAnswer) && currentAnswer.length > 0
                    )
                }
                return Boolean(currentAnswer && currentAnswer !== '')

            case 'checkbox':
                return Array.isArray(currentAnswer) && currentAnswer.length > 0

            case 'table':
                return newTables.every(
                    (table) =>
                        table.length > 0 &&
                        table.every(
                            (row) =>
                                row.product.trim() &&
                                row.qnt.trim() &&
                                row.price.trim() &&
                                parseFloat(row.qnt) > 0 &&
                                parseCurrencyInput(row.price) > 0
                        )
                )

            default:
                return false
        }
    }

    useEffect(() => {
        const storedProjectId = localStorage.getItem('projectId')
        const storedQuestionArray = localStorage.getItem('questionArray')
        if (questionArray.length > 0) {
            setIsLoading(false)
        } else {
            if (storedQuestionArray) {
                setQuestionArray(JSON.parse(storedQuestionArray))
                setProjectName(
                    localStorage.getItem('projectName')?.replace(/"/g, '') || ''
                )
            }
            if (storedQuestionArray?.length === 4) {
                setFinished(true)
                ResumeDoc()
            }

            setIsLoading(false)
        }
    }, [questionArray, setQuestionArray])

    const ResumeDoc = async () => {
        const projectId =
            localStorage.getItem('projectId')?.replace(/"/g, '') || ''
        const res = await api.getResume(projectId)
        setResume(res)
        console.log(resume)
    }

    const handleNext = async () => {
        if (activeStep === questionArray.length - 1) {
            const currentQuestion = questionArray[activeStep] as QuestionType
            const questionId = currentQuestion.id
            const projectGuid = currentQuestion.project_id
            const questionType = currentQuestion.question_type
            const selectedValue = formData[questionId]

            let content: any = {}

            if (questionType === 'select') {
                const options = currentQuestion.type.select?.options || []
                if (currentQuestion.type.select?.multiple) {
                    const selectedValues = selectedValue as string[]
                    content.text = selectedValues
                    content.indices = selectedValues
                        .map((value) => {
                            const option = options.find(
                                (opt) => opt.content === value
                            )
                            return option?.index
                        })
                        .filter(Boolean)
                } else {
                    const selectedOption = options.find(
                        (option) => option.content === selectedValue
                    )
                    if (selectedOption) {
                        content.text = selectedOption.content
                        content.index = selectedOption.index
                    }
                }
            } else if (questionType === 'table') {
                content.table = newTables.map((table) =>
                    table.map(({id, ...rest}) => rest)
                )
            } else {
                content[questionType] = selectedValue
            }

            try {
                const res = await api.postAnswer({
                    content: content,
                    question_id: questionId,
                    project_id: projectGuid,
                })

                if (!res) {
                    throw new Error(
                        'Resposta com status 204. Redirecionando...'
                    )
                }

                setQuestionArray([...questionArray, res])
                setActiveStep((prevStep) => prevStep + 1)
            } catch (error) {
                console.error(error)
                setFinished(true)
                ResumeDoc()
            }
            return
        }

        setActiveStep((prevStep) => prevStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1)
    }

    const handleChange = (
        id: string,
        value: string | boolean | {[key: string]: string | boolean}
    ) => {
        const currentQuestion = questionArray[activeStep] as QuestionType

        if (currentQuestion.question_type === 'checkbox') {
            const currentValues = (formData[id] as string[]) || []
            const valueStr = value as string

            let newValues: string[]
            if (currentValues.includes(valueStr)) {
                newValues = currentValues.filter((v) => v !== valueStr)
            } else {
                newValues = [...currentValues, valueStr]
            }

            setFormData((prevData) => ({
                ...prevData,
                [id]: newValues,
            }))
        } else if (currentQuestion.question_type === 'select') {
            if (currentQuestion.type.select?.multiple) {
                const currentValues = (formData[id] as string[]) || []
                const valueStr = value as string

                let newValues: string[]
                if (currentValues.includes(valueStr)) {
                    newValues = currentValues.filter((v) => v !== valueStr)
                } else {
                    newValues = [...currentValues, valueStr]
                }

                setFormData((prevData) => ({
                    ...prevData,
                    [id]: newValues,
                }))
            } else {
                setFormData((prevData) => ({
                    ...prevData,
                    [id]: value,
                }))
            }
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [id]: value,
            }))
        }
    }

    const addNewTable = () => {
        const newTable = [createEmptyRow(1)]
        setNewTables([...newTables, newTable])
    }

    const deleteTable = (tableIndex: number) => {
        if (tableIndex === 0) {
            toast.error('Não é possível excluir o primeiro lote')
            return
        }
        setNewTables((prevTables) =>
            prevTables.filter((_, index) => index !== tableIndex)
        )
    }

    const addRowToTable = (tableIndex: number) => {
        setNewTables((prevTables) => {
            const updatedTables = [...prevTables]
            const newRow = createEmptyRow(updatedTables[tableIndex].length + 1)
            updatedTables[tableIndex] = [...updatedTables[tableIndex], newRow]
            return updatedTables
        })
    }

    const finishProject = async () => {
        const projectId =
            localStorage.getItem('projectId')?.replace(/"/g, '') || ''

        try {
            await api.finishProject(projectId)
            setFinished(true)
        } catch (error) {
            console.error(error)
            setFinished(true)
        }
    }

    const deleteRowFromTable = (tableIndex: number, rowId: number) => {
        setNewTables((prevTables) => {
            const updatedTables = prevTables.map((table, index) =>
                index === tableIndex
                    ? table.filter((row) => row.id !== rowId)
                    : table
            )
            return updatedTables
        })
    }

    const formatPriceInput = (value: string): string => {
        let numbers = value.replace(/[^\d]/g, '')

        if (!numbers) return ''

        const numberInCents = parseInt(numbers)
        const formattedValue = (numberInCents / 100)
            .toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
            .replace(/\./g, '')

        console.log('formatPriceInput', value, formattedValue)
        return formattedValue
    }

    const handleInputChangeTable = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        tableIndex: number,
        rowIndex: number,
        field: keyof RowData
    ) => {
        const {value} = event.target

        setNewTables((prevTables) => {
            const updatedTables = [...prevTables]
            const currentRow = {...updatedTables[tableIndex][rowIndex]}

            if (field === 'price') {
                currentRow[field] = formatPriceInput(value)
                currentRow.total_price = calculateRowTotal(
                    currentRow.qnt,
                    currentRow.price
                )
            } else {
                ;(currentRow[field] as string) = value
                if (field === 'qnt') {
                    currentRow.total_price = calculateRowTotal(
                        currentRow.qnt,
                        currentRow.price
                    )
                }
            }

            updatedTables[tableIndex][rowIndex] = currentRow
            return updatedTables
        })
    }

    const renderTable = (table: RowData[], tableIndex: number) => (
        <Box
            component={Paper}
            sx={{
                width: '100%',
                backgroundColor: '#F6F8FA',
                marginBottom: 4,
                position: 'relative',
                boxShadow: 'none',
                borderRadius: '4px',
                overflow: 'auto',
            }}
        >
            <Table sx={{minWidth: 650}} aria-label="editable-table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{borderBottom: 'none'}}>Item</TableCell>
                        <TableCell sx={{borderBottom: 'none'}}>
                            Quantidade
                        </TableCell>
                        <TableCell sx={{borderBottom: 'none'}}>
                            Preço Unitário
                        </TableCell>
                        <TableCell sx={{borderBottom: 'none'}}>
                            Preço Global
                        </TableCell>
                        <TableCell width={50} sx={{borderBottom: 'none'}}>
                            Ações
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {table.map((row, rowIndex) => (
                        <TableRow key={row.id}>
                            <TableCell sx={{borderBottom: 'none'}}>
                                <TextField
                                    value={row.product}
                                    onChange={(e) =>
                                        handleInputChangeTable(
                                            e,
                                            tableIndex,
                                            rowIndex,
                                            'product'
                                        )
                                    }
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </TableCell>
                            <TableCell sx={{borderBottom: 'none'}}>
                                <TextField
                                    value={row.qnt}
                                    onChange={(e) =>
                                        handleInputChangeTable(
                                            e,
                                            tableIndex,
                                            rowIndex,
                                            'qnt'
                                        )
                                    }
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    fullWidth
                                />
                            </TableCell>
                            <TableCell sx={{borderBottom: 'none'}}>
                                <TextField
                                    value={row.price}
                                    onChange={(e) =>
                                        handleInputChangeTable(
                                            e,
                                            tableIndex,
                                            rowIndex,
                                            'price'
                                        )
                                    }
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    inputProps={{
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*',
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                R$
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </TableCell>
                            <TableCell sx={{borderBottom: 'none'}}>
                                {formatCurrency(
                                    parseFloat(row.total_price || '0')
                                )}
                            </TableCell>
                            <TableCell sx={{borderBottom: 'none'}}>
                                <IconButton
                                    onClick={() =>
                                        deleteRowFromTable(tableIndex, row.id)
                                    }
                                    color="error"
                                    size="small"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Box
                sx={{
                    marginTop: 2,
                    padding: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 6,
                }}
            >
                <Button
                    startIcon={<AddIcon />}
                    onClick={() => addRowToTable(tableIndex)}
                    sx={{
                        color: '#012441',
                        backgroundColor: 'transparent',
                        border: 'none',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            border: 'none',
                        },
                    }}
                >
                    Adicionar item
                </Button>
                {tableIndex !== 0 && (
                    <Button
                        startIcon={<DeleteIcon />}
                        onClick={() => deleteTable(tableIndex)}
                        sx={{
                            color: '#d32f2f',
                            backgroundColor: 'transparent',
                            border: 'none',
                            '&:hover': {
                                backgroundColor: 'transparent',
                                border: 'none',
                            },
                        }}
                    >
                        Apagar todo o lote
                    </Button>
                )}
                <Typography
                    variant="subtitle1"
                    sx={{
                        fontWeight: 'medium',
                        color: '#012441',
                    }}
                >
                    Preço global do lote:{' '}
                    {formatCurrency(calculateTableTotal(table))}
                </Typography>
            </Box>
        </Box>
    )

    const renderQuestion = (question: QuestionType) => {
        if (question.type.text) {
            return (
                <TextField
                    fullWidth
                    placeholder={question.content}
                    variant="outlined"
                    value={formData[question.id] || ''}
                    onChange={(e) => handleChange(question.id, e.target.value)}
                    InputLabelProps={{shrink: false}}
                />
            )
        } else if (question.type.radio) {
            return (
                <Box sx={{mb: 2}}>
                    <Typography variant="subtitle1" sx={{mb: 1}}>
                        {question.content}
                    </Typography>
                    <RadioGroup
                        value={formData[question.id] || ''}
                        onChange={(e) =>
                            handleChange(question.id, e.target.value)
                        }
                    >
                        {question.type.radio.options.map((option) => (
                            <FormControlLabel
                                key={option.index}
                                value={option.content}
                                control={<Radio />}
                                label={option.content}
                            />
                        ))}
                    </RadioGroup>
                </Box>
            )
        } else if (question.type.select) {
            return (
                <Stack direction="column" spacing={2}>
                    {question.type.select.options.map((option) => {
                        const currentValue = formData[question.id]
                        const isSelected = Array.isArray(currentValue)
                            ? currentValue.includes(option.content)
                            : currentValue === option.content

                        return (
                            <Button
                                style={{
                                    border: '1px solid #012441',
                                    color: '#012441',
                                    backgroundColor: isSelected
                                        ? '#8DBADF29'
                                        : 'transparent',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    textAlign: 'left',
                                }}
                                key={option.index}
                                variant={isSelected ? 'contained' : 'outlined'}
                                onClick={() =>
                                    handleChange(question.id, option.content)
                                }
                                fullWidth
                            >
                                {option.content}
                            </Button>
                        )
                    })}
                </Stack>
            )
        } else if (question.type.table) {
            return (
                <>
                    {newTables.map((table, tableIndex) =>
                        renderTable(table, tableIndex)
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2,
                            marginTop: 2,
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: '#012441',
                            }}
                        >
                            Preço global da contratação:{' '}
                            {formatCurrency(calculateGlobalTotal())}
                        </Typography>
                        <Button
                            onClick={addNewTable}
                            startIcon={<AddIcon />}
                            sx={{
                                color: '#012441',
                                backgroundColor: 'transparent',
                                border: 'none',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                },
                            }}
                        >
                            Adicionar lote
                        </Button>
                    </Box>
                </>
            )
        }
        return null
    }

    if (isLoading) {
        return (
            <Page>
                <Typography variant="h6">Carregando...</Typography>
            </Page>
        )
    }

    if (finished) {
        return (
            <Page>
                <Header>
                    <Typography variant="h4">{projectName}</Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            fontWeight: 400,
                            fontSize: '18px',
                            color: '#707070',
                            marginTop: '8px',
                        }}
                    >
                        Para seguir com novo documento precisamos que você
                        responda um breve formulário
                    </Typography>
                </Header>
                <ContentContainer style={{overflowY: 'auto'}}>
                    {resume.map((item, index) => (
                        <Box key={index} sx={{marginBottom: 2}}>
                            <Typography
                                variant="caption"
                                sx={{
                                    color: 'gray',
                                    fontSize: '0.75rem',
                                    marginBottom: '4px',
                                }}
                            >
                                Pergunta {index + 1} de {resume.length}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{fontWeight: 'bold'}}
                            >
                                {item.question_title}
                            </Typography>
                            {item.content.text && (
                                <Typography
                                    variant="body2"
                                    sx={{color: 'gray'}}
                                >
                                    {item.content.text}
                                </Typography>
                            )}
                            {item.content.table &&
                                item.content.table.length > 0 && (
                                    <>
                                        {item.content.table.map((table) => (
                                            <Table
                                                style={{
                                                    marginBottom: '16px',
                                                    border: '1px solid #ccc',
                                                }}
                                                sx={{minWidth: 650}}
                                                aria-label="simple table"
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            Item
                                                        </TableCell>
                                                        <TableCell>
                                                            Quantidade
                                                        </TableCell>
                                                        <TableCell>
                                                            Preço Unitário
                                                        </TableCell>
                                                        <TableCell>
                                                            Preço Global
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {table.map(
                                                        (row, rowIndex) => (
                                                            <TableRow
                                                                key={rowIndex}
                                                            >
                                                                <TableCell>
                                                                    {
                                                                        row.product
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {row.qnt}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {formatCurrency(
                                                                        parseFloat(
                                                                            row.price
                                                                        )
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {formatCurrency(
                                                                        parseFloat(
                                                                            row.total_price
                                                                        )
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    )}
                                                </TableBody>
                                            </Table>
                                        ))}
                                    </>
                                )}
                        </Box>
                    ))}
                </ContentContainer>
                <Footer>
                    <Typography
                        style={{marginLeft: '10%', fontSize: '18px'}}
                        variant="body2"
                    >
                        Tudo certo com seu documento?
                    </Typography>
                    <GradientButton
                        onClick={finishProject}
                        style={{marginRight: '10%'}}
                    >
                        Gerar Documento
                    </GradientButton>
                </Footer>
            </Page>
        )
    }

    return (
        <Page>
            <Header>
                <Typography variant="h4">{projectName}</Typography>
                <Typography
                    variant="subtitle1"
                    sx={{
                        fontWeight: 400,
                        fontSize: '18px',
                        color: '#707070',
                        marginTop: '8px',
                    }}
                >
                    Para seguir com novo documento precisamos que você responda
                    um breve formulário
                </Typography>
            </Header>
            <ContentContainer key={`question-${questionArray[activeStep]?.id}`}>
                <IconButton
                    onClick={() => navigate('/projetos')}
                    sx={{
                        position: 'absolute',
                        top: '16px',
                        right: '16px',
                        zIndex: 10,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography
                    variant="subtitle2"
                    sx={{color: 'gray', fontSize: '0.875rem', marginTop: '4px'}}
                >
                    {/* Pergunta {questionArray[activeStep]?.progress.answered} de{' '}
                    {questionArray[activeStep]?.progress.total} */}
                </Typography>
                <Typography
                    variant="h6"
                    sx={{
                        marginBottom: '24px',
                    }}
                >
                    {questionArray[activeStep]?.content}
                </Typography>
                {questionArray[activeStep] &&
                    renderQuestion(questionArray[activeStep] as QuestionType)}
            </ContentContainer>
            <NavigationButtons>
                {/* <Button
                    onClick={handleBack}
                    disabled={activeStep === 0}
                    sx={{mr: 1}}
                >
                    Voltar
                </Button> */}
                <Button
                    variant="contained"
                    onClick={handleNext}
                    disabled={!isCurrentQuestionAnswered()}
                >
                    Próximo
                </Button>
            </NavigationButtons>
        </Page>
    )
}
