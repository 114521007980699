import React, { useEffect, useState } from 'react'
import { Box, Step, StepLabel, Stepper, Typography, Button } from '@mui/material'
import styled from 'styled-components'
import Background from '../../../assets/background.svg'
import { useNavigate } from 'react-router-dom'

const steps = [
    'Processando respostas com IA',
    'Gerando documento',
    'Finalizado'
]

const Page = styled.div`
    width: 100%;
    height: 100vh;
    margin-top: 0;
    padding: 64px 48px 48px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    background-color: #f6f8fa;
    background-image: url(${Background});
    background-repeat: no-repeat;
    background-position: right 370px;
    background-size: 800px;
    align-items: center;
`

const Header = styled.div`
    width: 100%;
    margin-bottom: 32px;
`

const MainContainer = styled.div`
    width: 70%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
`

const ContentContainer = styled.div`
    width: 100%;
    background-color: #ffffff;
    padding: 32px;
    border-radius: 10px;
    margin-bottom: 24px;
`

const StyledStepper = styled(Stepper)`
    .MuiStepLabel-root {
        padding: 16px 0;
    }
    
    .MuiStepContent-root {
        margin-left: 20px;
    }
`

const StepperBody = ({ projectName = "Nome do documento" }) => {
    const [activeStep, setActiveStep] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()

    const goToNextStep = () => {
        setActiveStep((prevActiveStep) => {
            const nextStep = prevActiveStep + 1

            if (nextStep === steps.length - 1) {
                setIsLoading(false)
            }

            if (prevActiveStep < steps.length - 1) {
                return nextStep
            }
            return prevActiveStep
        })
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            goToNextStep()
        }, 3000)

        return () => clearInterval(intervalId)
    }, [])

    const handleNavigateHome = () => {
        navigate('/')
    }

    return (
        <Page>
            <Header>
                <Typography variant="h4">{projectName}</Typography>
                <Typography
                    variant="subtitle1"
                    sx={{
                        fontWeight: 400,
                        fontSize: '18px',
                        color: '#707070',
                        marginTop: '8px',
                    }}
                >
                    Aguarde enquanto o documento é gerado.
                </Typography>
            </Header>
            <MainContainer>
                <ContentContainer>
                    <StyledStepper activeStep={activeStep} orientation="vertical">
                        {steps.map((label, index) => (
                            <Step key={index}>
                                <StepLabel>
                                    <Typography variant="subtitle1" sx={{ color: '#012441' }}>
                                        {label}
                                    </Typography>
                                </StepLabel>
                                <Box sx={{ mb: 2, mt: 1, ml: 3 }}>
                                    <Typography variant="body2" color="text.secondary">
                                        Etapa {index + 1}
                                    </Typography>
                                </Box>
                            </Step>
                        ))}
                    </StyledStepper>

                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="text"
                            sx={{
                                textTransform: 'none',
                                color: isLoading ? '#808080' : '#1db8e8',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                                '&:active': {
                                    color: '#1473a6',
                                }
                            }}
                            disabled={isLoading}
                        >
                            Ver documento
                        </Button>
                    </Box>
                </ContentContainer>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <Button
                        variant="text"
                        onClick={handleNavigateHome}
                        sx={{
                            textTransform: 'none',
                            color: '#012441',
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                            '&:active': {
                                color: '#001829',
                            }
                        }}
                    >
                        Ir para página principal
                    </Button>
                </Box>
            </MainContainer>
        </Page>
    )
}

export default StepperBody