import styled, {keyframes} from 'styled-components'
import {COLORS} from '../../../../utils/colors'
import {useContext} from 'react'
import {HistoryContext} from '../../../../contexts/history'
import Message from './message/message'
import Input from './input/input'
import {breakpoints} from '../../../../utils/configs'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import Background from '../../../../assets/background.svg'

const Section = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    position: relative;
    background-color: #f6f8fa;
    background-image: url(${Background});
    background-repeat: no-repeat;
    background-position: right 370px;
    background-size: 800px;

    @media screen and (${breakpoints.sm}) {
        width: 100vw;
    }
`

const Body = styled.div<{noMessage?: boolean}>`
    flex: 1;
    display: flex;
    flex-direction: column;
    ${({noMessage}) => (noMessage ? 'justify-content: center;' : '')}
    width: 795px;
    max-width: 100%;

    overflow-y: auto;
    margin-top: 50px;
`

const BodyMessage = styled.div<{isUser?: boolean}>`
    display: flex;
    justify-content: ${({isUser}) => (isUser ? 'flex-end' : 'flex-start')};
    width: 100%;
    padding: 5px 0;
`

const SelectedSchiefMessage = styled.span`
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    text-align: center;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
`
const NoMessageText = styled.span`
    align-self: center;
    font-weight: 400;
`

const ModalContent = styled.div`
    background-color: white;
    border-radius: 8px;
    width: 100%;
    max-width: 80%;
    max-height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 24px;
    outline: none;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        /* Para tablets e maiores */
        width: 60%;
    }

    @media (min-width: 1024px) {
        /* Para telas de desktop */
        width: 40%;
    }
`

const StyledIconButton = styled(IconButton)``

const HeadModal = styled.div`
    display: flex;
    padding: 20px;
    justify-content: space-between;
`

const BodyModal = styled.div`
    aling-content: center;
    display: flex;
    padding: 20px;
    overflow-y: auto;
`
export default function Chat() {
    const {
        messagesArray,
        selected,
        assistant,
        setUnarchieveModal,
        unarchieveModal,
        archivedThread,
        unarchiveThread,
    } = useContext(HistoryContext)
    const handleClose = () => {
        setUnarchieveModal(false)
    }

    const _desarquivarModal = () => {
        return (
            <Modal
                onClose={handleClose}
                open={unarchieveModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalContent>
                    <HeadModal>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                        >
                            Desarquivar Chats
                        </Typography>
                        <StyledIconButton onClick={handleClose}>
                            <CloseIcon />
                        </StyledIconButton>
                    </HeadModal>
                    <Divider orientation="horizontal" flexItem />
                    <BodyModal>
                        <TableContainer component={Box}>
                            <Table
                                sx={{minWidth: 200}}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nome</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {archivedThread?.map((item) => (
                                        <TableRow
                                            key={item.guid}
                                            sx={{
                                                '&:last-child td, &:last-child th':
                                                    {border: 0},
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <ChatOutlinedIcon
                                                    sx={{marginRight: '10px'}}
                                                />
                                                {item.name}
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton
                                                    onClick={() =>
                                                        unarchiveThread(
                                                            item.guid
                                                        )
                                                    }
                                                >
                                                    <UnarchiveOutlinedIcon />
                                                </IconButton>
                                                {
                                                    // <IconButton>
                                                    //     <DeleteOutlinedIcon
                                                    //         onClick={() =>
                                                    //             deleteThread(
                                                    //                 item.guid
                                                    //             )
                                                    //         }
                                                    //     />
                                                    // </IconButton>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </BodyModal>
                </ModalContent>
            </Modal>
        )
    }

    return (
        <>
            <Section>
                {/* <SelectedSchiefMessage>
                    {assistant?.guid
                        ? `Você selecionou o Schief ${assistant.name}.`
                        : 'Selecione um assistente no menu abaixo.'}
                </SelectedSchiefMessage> */}
                {!!selected?.name ? (
                    <Body noMessage={false}>
                        {messagesArray?.map((item, index) => (
                            <BodyMessage
                                key={`body-${item.guid}`}
                                isUser={!!item.author_id}
                            >
                                <Message
                                    key={item.guid}
                                    message={item}
                                    arrayLength={messagesArray.length}
                                    index={index}
                                />
                            </BodyMessage>
                        ))}
                        <div id="anchor"></div>
                    </Body>
                ) : (
                    <Body noMessage>
                        <NoMessageText>Selecione uma conversa.</NoMessageText>
                    </Body>
                )}
                <Input />
            </Section>
            {_desarquivarModal()}
        </>
    )
}
